@import '../../assets/scss/variable';
@import '../../assets/scss/mixin';

.gateways-wrapper {
  margin-top: 36px;
  @include screen-mobile {
    margin-top: 60px;
  }

  .gateways-title {
    @include font(18px, 400, normal, 34px);
    color: $black;
  }

  .gateways-icons {
    ul {
      display: flex;
      justify-content: flex-start;

      li {
        margin-right: 40px;
        img {
          width: 100%;
        }
        @include screen-mobile {
          margin-right: 36px;
          img {
            width: 100%;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
