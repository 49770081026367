$white: #FFFFFF;
$black: #000000;
$lightGrey: #767676;
$bodyBackground: #EEEEEE;
$textColor: #767676;
$darkBlue: #000D2E;
$copyrightColor: #898989;
$seperatorColor: #8F8E8E;
$linkBlue: #32E1CC;
$borderColor: #8F8E8E;
$whiteButtonText: #212121;
$boxShadowColor1: #ECECEC;
$boxShadowColor2: rgba(0, 0, 0, 0.08);
$footerBackground: #190a4d;
$blue: #5218FA;

// Nav
$navLinkWhite: #FFFFFF;
$navLinkBlack: #000D2E;

// Carousel 

$gradientColor1: #54C5C3;
$gradientColor2: #512E87;
$gradientColor3: #EF515F;

// Button
$blueGrad1: #ABABF3;
$blueGrad2: #4B1CF0;
$blueBoxShadow: #4117D6;

// Card
$boxShadow1: #ECECEC;
$boxShadow2: rgba(0, 0, 0, 0.08);

// In focus card variables
$descriptionColor: #424B5A;

// Careers details component
$listSeperatorColor: #C4C4C4;
$tabHeaderSeperatorColor: #C2D1D9;
$tabDescription: #505D68;

// gradients
$gradient-primary: linear-gradient(86.17deg, #ABABF3 -27.73%, #4B1CF0 78.29%);