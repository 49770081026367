@import '../../assets/scss/variable';
@import '../../assets/scss/mixin';
// .key-chain-widget-wrapper {
//   max-width: 1000px;
//   background-image: url('../../assets/images/finamia-background.svg');
//   height: 301px;
//   margin: 10% auto;
//   background-color: #fff;
//   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
//   border-radius: 16px;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
//   padding: 5%;
//   @include screen-tablet {
//     max-width: 700px;
//   }
//   @include screen-mobile {
//     max-width: 90%;
//     height: 300px;
//     flex-direction: column;
//   }
//   .key-chain-widget {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     @include screen-mobile {
//       align-items: center;
//       text-align: center;
//     }

//     .key-chain-title {
//       color: #000000;
//       font-weight: 400;
//       font-size: 32px;
//       @include screen-tablet {
//         font-size: 24px;
//       }
//       @include screen-mobile {
//         font-size: 20px;
//         font-weight: 500;
//       }
//     }

//     .navigate-button {
//       margin-top: 5%;
//       a {
//         background: #ff00b0;
//         border-radius: 6px;
//         box-shadow: 0px 4px 0px #ff00b0;
//         @include screen-mobile {
//           font-size: 15px;
//         }
//       }
//     }
//   }
//   .key-chain-image {
//     align-items: center;
//     justify-content: center;
//     display: flex;

//     img {
//       width: 100%;
//       @include screen-mobile {
//         width: 80%;
//       }
//     }
//   }

//   .content-container {
//     .title-wrapper {
//       @include screen-mobile {
//         margin-bottom: 12px;
//       }

//       .text {
//         @include screen-mobile {
//           @include font(36px, 700, normal, 36px);

//           b {
//             @include font(36px, 700, normal, 36px);
//           }
//         }
//       }

//       .icon {
//         @include screen-mobile {
//           max-width: 90px;
//         }

//         img {
//           @include screen-mobile {
//             width: 100%;
//           }
//         }
//       }
//     }

//     .content-wrapper {
//       &.isList {
//         @include screen-mobile {
//           margin-bottom: 0;
//         }

//         .isListTitleDescription {
//           @include screen-mobile {
//             margin-bottom: 16px;

//             &:last-child {
//               margin-bottom: 0;
//             }
//           }

//           .title {
//             @include screen-mobile {
//               @include font(16px, 600, normal, 24px);
//             }
//           }

//           .description {
//             @include screen-mobile {
//               @include font(16px, 400, normal, 24px);
//             }
//           }
//         }
//       }
//     }
//   }
// }

.text-container {
  align-items: end;
  text-align: center;
  display: flex;
  flex: 1;
  justify-content: center;
  color: #000;
  height: 70vh;

  .hero-text {
    font-size: 50px;
    font-weight: 500;
    margin-bottom: 30px;

    @include screen-mobile {
      @include font(26px, 700, normal, 34px);
    }
    @include screen-tablet {
      @include font(36px, 700, normal, 44px);
    }
  }

  .support-text {
    font-size: 28px;
    line-height: 45px;
    margin-bottom: 40px;

    @include screen-mobile {
      @include font(20px, 500, normal, 28px);
    }
    @include screen-tablet {
      @include font(26px, 500, normal, 32px);
    }
  }
}
