.carousel-wrapper {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    // background: $gradient-primary;
    background: $gradient-primary;

    &.is-carousel {
        background: transparent;

        .carousel-container {
            height: auto;
            border-radius: 0;
            position: relative;
            margin-bottom: 60px;

            @media (min-height: 700px) {
                height: auto;
            }

            @media (max-height: 699px) {
                height: auto;
            }

            @include screen-tablet-landscape {
                height: auto;
            }

            @include screen-tablet-portrait {
                height: auto;
            }

            @include screen-mobile {
                height: auto;
                margin-bottom: 30px;
                @include gutter(0);
                background: none;
                margin-top: 61px;

                &::after {
                    content: '';
                    width: 100%;
                    height: 61px;
                    border-radius: 0;
                    background: $gradient-primary;
                    position: absolute;
                    left: 0;
                    top: -61px;
                }
            }

            .carousel {

                .carousel-indicators,
                .carousel-control-prev,
                .carousel-control-next {
                    visibility: hidden;
                    opacity: 0;
                    transition: 0.2s;

                    @include screen-mobile {
                        visibility: hidden !important;
                        opacity: 0 !important;
                    }
                }
            }

            &:hover {
                .carousel {

                    .carousel-indicators,
                    .carousel-control-prev,
                    .carousel-control-next {
                        visibility: visible;
                        opacity: 1;
                        transition: 0.2s;
                    }
                }
            }

            .carousel-description {
                display: none;
                @include font(20px, 400, normal, 36px);
                color: $white;
                position: absolute;
                left: 0;
                bottom: 10%;
                width: 40%;
                padding-left: 36px;

                @include screen-tablet-portrait {
                    padding-left: 5%;
                    bottom: 20%;
                    @include font(12px, 400, normal, 24px);
                }

                @include screen-tablet-landscape {
                    padding-left: 5%;
                    @include font(12px, 400, normal, 24px);
                }

                @include screen-mobile {
                    @include font(8px, 400, normal, 16px);
                    width: 45%;
                    padding-left: 5%;
                    bottom: 1%;
                }
            }

            .button-container {
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                position: absolute;
                left: 0;
                bottom: 30px;

                @include screen-mobile {
                    position: relative;
                    bottom: 0;
                    @include gutter(24);
                }

                .button-wrapper {
                    max-width: 1440px;
                    margin-left: auto;
                    margin-right: auto;
                    padding-left: 36px;
                    padding-right: 36px;

                    @include screen-mobile {
                        padding-left: 0;
                        padding-right: 0;

                        .btn-app {
                            span {
                                min-width: 140px;
                            }
                        }
                    }
                }
            }
        }
    }

    @include screen-mobile {
        background: transparent;
    }

    .carousel-container {
        height: calc(100vh - 60px);
        border-radius: 0 0 48px 48px;
        position: relative;
        margin-bottom: 60px;

        @media (min-height: 700px) {
            height: 700px;
        }

        @media (max-height: 699px) {
            height: 640px;
        }

        &.is-video-carousel {
            @media (min-height: 700px) {
                height: 500px;
            }

            @media (max-height: 699px) {
                height: 500px;
            }

            @include screen-tablet-landscape {
                height: 500px;
            }

            @include screen-tablet-portrait {
                height: 440px;
            }

            @include screen-mobile {
                height: auto;
                margin-bottom: 30px;
                @include gutter(24);
                background: none;

                &::after {
                    content: '';
                    width: 100%;
                    height: 411px;
                    border-radius: 0 0 24px 24px;
                    background: $gradient-primary;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }
        }

        @include screen-tablet-landscape {
            height: 540px;
        }

        @include screen-tablet-portrait {
            height: 440px;
        }

        @include screen-mobile {
            height: auto;
            margin-bottom: 30px;
            @include gutter(24);
            background: none;

            &::after {
                content: '';
                width: 100%;
                height: 411px;
                border-radius: 0 0 24px 24px;
                background: $gradient-primary;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }

    .container,
    .row {
        height: 100%;
    }

    .carousel-body {

        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        height: 100%;
        max-width: 1068px;
        margin: auto;

        @include screen-tablet-landscape {
            width: 50%;
            margin-left: 24px;
        }

        @include screen-tablet-portrait {
            width: 50%;
            margin-left: 24px;
        }

        @include screen-mobile {
            height: auto;
            position: relative;
            z-index: 1;
            max-width: 520px;

        }

        .carousel-body-left {
            display: flex;
            justify-content: flex-end;
            height: 100%;
            flex-direction: column;
            width: 52%;
            margin-bottom: 10%;

            @include screen-tablet {
                width: 100%;
                ;
            }

            @include screen-mobile {
                width: 100%;
                margin-bottom: 0;
                margin-top: 61px;
                justify-content: flex-end;
            }

            .text-wrapper {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                width: 100%;
                color: $white;
                margin-bottom: 45px;

                @include screen-tablet-portrait {
                    margin-bottom: 10px;
                }

                @include screen-mobile {
                    margin-bottom: 0;
                    margin-top: 30px;
                }

                b {
                    font-size: 16px;
                    font-size: calc(min(max(52px, 10.6vw), 140px));
                    font-weight: 600;
                    line-height: 1;

                    @include screen-mobile {
                        line-height: 78px;
                    }
                }

                span {
                    font-size: 5.625vw;
                    font-size: calc(min(max(36px, 5.625vw), 72px));
                    line-height: 1.5;

                    @include screen-mobile {
                        line-height: 46px;
                    }
                }
            }

            .description {
                @include font(20px, 400, normal, 36px);
                color: $white;
            }

            .button-wrapper {
                @include screen-tablet {
                    flex-direction: column;
                    justify-self: flex-start;
                    align-items: flex-start;

                    .btn {
                        width: auto;
                        padding: 10px;
                        margin-right: 00px;
                        margin-bottom: 10px;
                    }
                }

                @include screen-mobile {
                    display: none;
                }
            }
        }

    }

    .carousel-body-right {
        display: flex;
        align-items: flex-end;
        height: 100%;
        position: absolute;
        bottom: -20%;
        right: 36px;
        width: 48%;

        @include screen-tablet-landscape {
            right: 24px;
            bottom: -20%;
            width: 55%;

            span {
                height: 100%;
            }
        }

        @include screen-tablet-portrait {
            right: 24px;
            bottom: -35%;
            width: 55%;

            span {
                height: 100%;
            }
        }

        img {
            height: 100%;

            @include screen-mobile {
                width: 100%;
            }
        }

        @include screen-mobile {
            bottom: 25px;
            width: 100%;
            right: auto;
            height: auto;
            position: relative;
            z-index: 1;
            max-width: 520px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .button-wrapper {
        @include screen-mobile {
            flex-direction: column;
            padding-top: 0px;
            padding-bottom: 0px;
            margin-top: 30px;

            a {
                justify-content: center !important;
                margin-right: 0 !important;
                min-width: auto;
                width: 100%;
                margin-bottom: 10px;
                background: linear-gradient(86.17deg, $blueGrad1 -27.73%, $blueGrad2 78.29%);
                box-shadow: 0px 4px 0px $blueBoxShadow;
                margin-bottom: 14px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }


}

.inner-page-banner {
    &.carousel-wrapper {
        .carousel-container {
            display: flex;
            flex-wrap: wrap;

            @include screen-tablet {
                height: 400px;
            }

            @include screen-mobile {
                border-radius: 0 0 20px 20px;
                background: $gradient-primary;


            }

            .button-wrapper {
                display: none;
            }

            .carousel-body {
                max-width: 562.5px;
                margin-left: auto;
                margin-right: 0;
                padding-bottom: 5rem;

                @include screen-tablet {
                    padding-bottom: 24px;
                    margin-left: 24px;
                    margin-right: auto;
                    width: calc(50% - 24px);
                }

                @include screen-mobile {
                    padding-bottom: 24px;
                    margin-left: 0;
                }

                .carousel-body-left {
                    width: 100%;

                    .text-wrapper {
                        max-width: 498px;
                        @include font(70px, 700, normal, 82px);
                        margin-bottom: 10px;

                        @include screen-mobile {
                            @include font(52px, 600, normal, 78px);
                        }

                        b {
                            @include font(70px, 700, normal, 82px);
                        }
                    }

                    .description {
                        max-width: 498px;

                        @include screen-tablet {
                            @include font(16px, 400, normal, 24px);
                        }

                        @include screen-mobile {
                            @include font(16px, 400, normal, 24px);
                        }
                    }

                    .button-wrapper {
                        max-width: 498px;
                    }
                }

            }

            .carousel-body-right {
                margin-right: auto;
                width: 562.5px;
                position: relative;
                right: 0;
                bottom: 0 !important;
                padding-bottom: 10% !important;
                display: flex;
                justify-content: flex-end;

                @include screen-large-screens {
                    padding-bottom: 160px !important;
                }

                @include screen-tablet {
                    width: calc(50% - 24px);
                    margin-right: 24px;
                    display: flex !important;
                    justify-content: center;
                    align-items: flex-end;
                    padding-bottom: 80px !important;

                    img {
                        width: 80%;
                    }
                }

                @include screen-mobile {
                    width: 100%;
                    justify-content: center;
                    padding-bottom: 23px;
                    margin-left: 0;

                    img {
                        max-width: 203px !important;
                    }
                }

                img {
                    height: auto;
                    max-width: 460px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}

.partners-banner {
    &.carousel-wrapper {
        .carousel-container {
            max-width: 1920px;
            margin: auto;

            @include screen-tablet {
                height: 400px;
            }

            @include screen-mobile {
                margin-top: 61px;
                // &::after {
                //     height: 472px;
                // }
            }

            .carousel-body {
                max-width: 498px;
                margin-left: calc(13% + 4px);

                @include screen-tablet {
                    margin-left: 24px;
                    width: calc(50% - 24px);
                }

                @include screen-mobile {
                    margin-left: 0;
                }

                .carousel-body-left {
                    width: 100%;
                    margin-bottom: 30%;

                    @include screen-tablet {
                        margin-bottom: 20%;
                    }

                    @include screen-mobile {
                        margin-bottom: 30px;
                    }

                    .text-wrapper {
                        margin-bottom: 10px;
                        @include font(70px, 700, normal, 82px);
                        margin-bottom: 10px;

                        @include screen-mobile {
                            @include font(52px, 600, normal, 78px);
                        }

                        b {
                            @include font(70px, 700, normal, 82px);
                        }
                    }

                    .description {
                        max-width: 400px;

                        @include screen-tablet {
                            width: 90%;
                            @include font(16px, 400, normal, 24px);
                        }

                        @include screen-mobile {
                            @include font(16px, 400, normal, 24px);
                        }
                    }

                    .button-wrapper {
                        display: none !important;
                    }
                }
            }

            .carousel-body-right {
                width: auto;
                padding-bottom: 0 !important;
                bottom: -50px !important;

                @media (min-width: 1200px) and (max-width: 1300px) {
                    max-width: 534px;
                }


                @include screen-tablet {
                    width: calc(50% - 24px);
                    right: 24px;
                    bottom: -50px !important;
                    display: flex !important;
                    justify-content: center;
                    align-items: flex-end;
                }

                @include screen-tablet-landscape {
                    max-width: 472px !important;
                    margin-right: 0;
                }

                @include screen-mobile {
                    width: 100%;
                    justify-content: center;
                    bottom: 0px !important;
                }

                img {
                    height: auto;
                }
            }

            .button-wrapper {
                @include screen-mobile {
                    display: none !important;
                }
            }

            .button-container {
                display: none;

                @include screen-mobile {
                    display: none !important;
                }
            }
        }
    }

}

.blogs-page-banner {
    &.carousel-wrapper {
        .carousel-container {
            display: flex;
            flex-wrap: wrap;

            @include screen-tablet {
                height: 400px;
            }

            @include screen-mobile {
                border-radius: 0 0 20px 20px;
                background: $gradient-primary;

                &::after {
                    display: none;
                }
            }

            .button-wrapper {
                display: none;
            }

            .carousel-body {
                max-width: 562.5px;
                margin-left: auto;
                margin-right: 0;
                padding-bottom: 5rem;

                @include screen-tablet {
                    padding-bottom: 2rem;
                    width: calc(60% - 24px);
                    margin-left: 24px;
                }

                @include screen-mobile {
                    max-width: 100%;
                    width: 100%;
                    padding-bottom: 5px;
                    margin-left: 0;
                    margin-right: auto;
                }

                .carousel-body-left {
                    width: 100%;

                    .text-wrapper {
                        max-width: 498px;
                        @include font(70px, 700, normal, 82px);
                        margin-bottom: 10px;

                        @include screen-mobile {
                            @include font(52px, 600, normal, 78px);
                        }

                        b {
                            @include font(70px, 700, normal, 82px);
                        }
                    }

                    .description {
                        max-width: 498px;

                        @include screen-mobile {
                            @include font(16px, 400, normal, 24px);
                            max-width: 100%;
                            margin-left: 0;
                        }
                    }

                    .button-wrapper {
                        max-width: 498px;
                    }
                }

            }

            .carousel-body-right {
                margin-right: auto;
                width: 562.5px;
                position: relative;
                right: 0;
                bottom: 0 !important;
                padding-bottom: 5% !important;
                display: flex;
                justify-content: center;

                @include screen-large-screens {
                    padding-bottom: 100px !important;
                }

                @include screen-tablet {
                    img {
                        max-width: 203px !important;
                    }
                }

                @include screen-tablet {
                    width: calc(40% - 24px);
                    margin-right: 24px;

                    span {
                        display: flex !important;
                        justify-content: center;
                        align-items: flex-end;

                        img {
                            width: 50%;
                            margin-bottom: 1rem;
                        }
                    }
                }

                img {
                    height: auto;
                    max-width: 460px;
                    margin-left: auto;
                    margin-right: auto;

                    @include screen-mobile {
                        max-width: 128px;
                    }
                }
            }
        }

    }
}

.about-banner {
    .carousel-container {
        .carousel-description {
            display: block !important;
        }
    }
}

.blogs-page-banner {
    .carousel-title#blog-title {
        position: absolute;
        left: 10%;
        top: 20%;
        font-weight: bold;
        font-size: 70px;
        color: #FFFFFF;
        display: flex;
        flex-direction: column;

        @include screen-tablet-portrait {
            font-size: 50px;
            top: 15%
        }

        @include screen-mobile {
            left: 5%;
            font-size: 20px;
        }

    }

    .title-with-description {
        @include font(30px, bold, normal, 36px);
        width: 30%;
        padding-left: 0%;
        display: flex;
        position: relative;

        @include screen-tablet-portrait {
            @include font(22px, bold, normal, 30px);
            width: 38%;
        }

        @include screen-tablet-landscape {
            @include font(22px, bold, normal, 24px);
        }

        @include screen-mobile {
            @include font(10px, 400, normal, 16px);
            width: 35%;
        }
    }
}