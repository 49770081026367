.owl-carousel {
    .owl-stage-outer {
        .owl-stage {
            .owl-item {}
        }
    }

    .owl-nav {
        button {
            position: absolute;
            top: calc(50% - 15px);
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            opacity: 1;

            span {
                display: none;
            }

            @include screen-mobile {
                height: 16px;
                width: 16px;
            }

            &::after {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }

            &.owl-prev {
                left: -50px;

                @include screen-mobile {
                    left: -20px;
                }

                &::after {
                    background-image: url(./../../images/left-arrow.svg);
                }
            }

            &.owl-next {
                right: -50px;

                @include screen-mobile {
                    right: -20px;
                }

                &::after {
                    background-image: url(./../../images/right-arrow.svg);
                }
            }

        }
    }

    .owl-dots {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        margin-top: 40px;

        .owl-dot {
            width: 40px;
            height: 4px;
            background-color: $white;
            border-radius: 4px;
            margin-right: 15px;
            @include screen-mobile {
                width: 32px;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}