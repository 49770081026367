@import '../../assets/scss/variable';
@import '../../assets/scss/mixin';
.containerBox {
  display: flex;
  width: 70%;
  margin: 10% auto 5% auto;
  align-items: center;
  @include screen-mobile {
    flex-direction: column;
    width: 80%;
  }
  @include screen-tablet {
    width: 90%;
  }
  .image-container {
    width: 50%;
    @include screen-mobile {
      width: 100%;
      margin-bottom: 5%;
      margin-top: 15%;
    }
  }

  .content-container {
    width: 50%;
    @include screen-mobile {
      width: 100%;
    }
    .title-wrapper {
      @include screen-mobile {
        margin-bottom: 12px;
      }

      .text {
        font-size: 32px;
        @include screen-mobile {
          @include font(24px, 500, normal, 30px);

          b {
            @include font(24px, 500, normal, 30px);
          }
        }
        @include screen-tablet {
          @include font(28px, 500, normal, 32px);

          b {
            @include font(28px, 500, normal, 32px);
          }
        }
      }

      .icon {
        @include screen-mobile {
          max-width: 90px;
        }

        img {
          @include screen-mobile {
            width: 100%;
          }
        }
      }
    }

    .content-wrapper {
      //   &.isList {
      //     @include screen-mobile {
      //       margin-bottom: 0;
      //     }

      .list {
        display: flex;
        font-size: 20px;
        color: black;
        line-height: 40px;
        align-items: start;

        .bullet-box {
          margin-right: 10px;
          width: 25px;
          height: 25px;
          background-color: #8a37f3;
          text-align: center;
          color: #fff;
          border-radius: 20px;
          display: flex;
          align-items: center;

          p {
            flex: 1;
            width: 50%;
            font-size: 16px;
            margin-top: 15px;
          }
        }

        .isListTitleDescription {
          @include screen-mobile {
            margin-bottom: 16px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          .description {
            margin-top: -10px;
            @include screen-mobile {
              @include font(18px, 400, normal, 30px);
            }
          }
          .cta-box {
            img {
              width: 120px;
              margin: -10px 10px 10px 0;
            }
          }
        }
      }
      .list1 {
        display: flex;
        font-size: 20px;
        color: black;
        line-height: 40px;
        align-items: start;
        margin-top: 20px;

        .bullet-box {
          margin-right: 10px;
          width: 25px;
          height: 25px;
          background-color: #8a37f3;
          text-align: center;
          color: #fff;
          border-radius: 20px;
          display: flex;
          align-items: center;

          p {
            flex: 1;
            width: 50%;
            font-size: 16px;
            margin-top: 15px;
          }
        }

        .isListTitleDescription {
          @include screen-mobile {
            margin-bottom: 16px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          .description {
            margin-top: -10px;
            @include screen-mobile {
              @include font(18px, 400, normal, 30px);
            }
          }
        }
      }
    }
  }
}
