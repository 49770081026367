@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

body,
html {
    margin: 0;
    padding: 0;
    font-family: 'Nunito', sans-serif;
    background: $bodyBackground;
    color: $textColor;
    font-display: swap;
    scroll-behavior: smooth;

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px $lightGrey;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $blue;
        border-radius: 0px;
    }

    &.overflow-hidden {
        overflow: hidden !important;
    }

    .pointer {
        cursor: pointer !important;
    }

    .loader-wrapper {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        background-color: $white;
        z-index: 99999;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 1;
        visibility: visible;

        &.hide {
            opacity: 0;
            visibility: hidden;
            transition: 0.3s ease-in-out;
        }

        img {
            max-width: 500px;
            min-width: 320px;
        }
    }

    &.overflow-y-hidden {
        overflow-y: hidden !important;
    }

    &.overflow-x-hidden {
        overflow-x: hidden !important;
    }
}

ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

@for $i from 5 to 101 {
    .pl-#{$i} {
        padding-left: $i * 1px !important;
    }

    .pt-#{$i} {
        padding-top: $i * 1px !important;
    }

    .pr-#{$i} {
        padding-right: $i * 1px !important;
    }

    .pb-#{$i} {
        padding-bottom: $i * 1px !important;
    }
}

@include screen-mobile {

    @for $i from 0 to 101 {
        .pl-sm-#{$i} {
            padding-left: $i * 1px !important;
        }

        .pt-sm-#{$i} {
            padding-top: $i * 1px !important;
        }

        .pr-sm-#{$i} {
            padding-right: $i * 1px !important;
        }

        .pb-sm-#{$i} {
            padding-bottom: $i * 1px !important;
        }
    }

}

@include screen-tablet {

    @for $i from 0 to 101 {
        .pl-md-#{$i} {
            padding-left: $i * 1px !important;
        }

        .pt-md-#{$i} {
            padding-top: $i * 1px !important;
        }

        .pr-md-#{$i} {
            padding-right: $i * 1px !important;
        }

        .pb-md-#{$i} {
            padding-bottom: $i * 1px !important;
        }
    }

}

@include screen-laptops {
    @for $i from 0 to 101 {
        .pl-lg-#{$i} {
            padding-left: $i * 1px !important;
        }

        .pt-lg-#{$i} {
            padding-top: $i * 1px !important;
        }

        .pr-lg-#{$i} {
            padding-right: $i * 1px !important;
        }

        .pb-lg-#{$i} {
            padding-bottom: $i * 1px !important;
        }
    }
}

@for $i from 5 to 101 {
    .ml-#{$i} {
        margin-left: $i * 1px !important;
    }

    .mt-#{$i} {
        margin-top: $i * 1px !important;
    }

    .mr-#{$i} {
        margin-right: $i * 1px !important;
    }

    .mb-#{$i} {
        margin-bottom: $i * 1px !important;
    }
}

@include screen-mobile {

    @for $i from 0 to 101 {
        .ml-sm-#{$i} {
            margin-left: $i * 1px !important;
        }

        .mt-sm-#{$i} {
            margin-top: $i * 1px !important;
        }

        .mr-sm-#{$i} {
            margin-right: $i * 1px !important;
        }

        .mb-sm-#{$i} {
            margin-bottom: $i * 1px !important;
        }
    }

}

@include screen-tablet {

    @for $i from 0 to 101 {
        .ml-md-#{$i} {
            margin-left: $i * 1px !important;
        }

        .mt-md-#{$i} {
            margin-top: $i * 1px !important;
        }

        .mr-md-#{$i} {
            margin-right: $i * 1px !important;
        }

        .mb-md-#{$i} {
            margin-bottom: $i * 1px !important;
        }
    }

}

@include screen-laptops {
    @for $i from 0 to 101 {
        .ml-lg-#{$i} {
            margin-left: $i * 1px !important;
        }

        .mt-lg-#{$i} {
            margin-top: $i * 1px !important;
        }

        .mr-lg-#{$i} {
            margin-right: $i * 1px !important;
        }

        .mb-lg-#{$i} {
            margin-bottom: $i * 1px !important;
        }
    }
}


@for $w from 1 to 101 {
    .wid-#{$w} {
        width: $w * 1% !important;
        max-width: $w * 1% !important;
    }

    .wid-sm-#{$w} {
        @include screen-mobile {
            width: $w * 1% !important;
            max-width: $w * 1% !important;
        }
    }

    .wid-md-#{$w} {
        @include screen-tablet {
            width: $w * 1% !important;
            max-width: $w * 1% !important;
        }
    }

    .wid-lg-#{$w} {
        @include screen-laptops {
            width: $w * 1% !important;
            max-width: $w * 1% !important;
        }
    }
}

.hidden {
    display: none !important;
}

.hidden-sm {
    @include screen-mobile {
        display: none !important;
    }
}

.hidden-md {
    @include screen-tablet {
        display: none !important;
    }
}

.hidden-lg {
    @include screen-laptops {
        display: none !important;
    }
}