@mixin font($fontSize, $fontWeight, $fontStyle, $lineHeight) {
  font-size: $fontSize;
  font-weight: $fontWeight;
  font-style: $fontStyle;
  line-height: $lineHeight;
}

@mixin maxLine($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  /* number of lines to show */
  -webkit-box-orient: vertical
}

@mixin gutter($width) {
  padding-left: $width * 1px;
  padding-right: $width * 1px;
}

// usage : @include screen-mobile {...}
@mixin screen-mobile {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

// usage : @include screen-mobile {...}
@mixin screen-mobile-tablet {
  @media only screen and (max-width: 991px) {
    @content;
  }
}

// usage : @include screen-tablet {...}
@mixin screen-tablet {
  @media only screen and (min-width: 768px) and (max-width: 1200px) {
    @content;
  }
}

// usage : @include screen-tablet-portrait {...}
@mixin screen-tablet-portrait {
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}

// usage : @include screen-tablet-landscape {...}
@mixin screen-tablet-landscape {
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    @content;
  }
}

// usage : @include screen-large-screens {...}
@mixin screen-large-screens {
  @media only screen and (min-width: 1441px) {
    @content;
  }
}

// usage : @include screen-laptops {...}
@mixin screen-laptops {
  @media only screen and (min-width: 992px) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}