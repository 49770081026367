.button-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    button {
        margin-right: 32px;

        &:last-child {
            margin-right: 0;
        }
    }

    &.isLink {
        a {
            margin-right: 32px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.btn {
    border-radius: 6px;
    box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    color: $white;
    min-width: 200px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: none !important;
    border: none !important;
    border-radius: 6px;
    transition: 0.1s;

    &.btn-blue {
        background: linear-gradient(86.17deg, $blueGrad1 -27.73%, $blueGrad2 78.29%);
        box-shadow: 0px 4px 0px $blueBoxShadow;

        &.btn-black-text {
            @include font(16px, 600, normal, 24px);

            span {
                color: $black !important;
            }
        }
    }



    &.btn-white-secondary {
        background: $white;
        box-shadow: 0px 4px 0px #dfdfdf;


        &.btn-black-text {
            @include font(16px, 600, normal, 24px);

            span {
                color: $black !important;
            }
        }
    }

    &.btn-white {
        background: $white;
        color: $whiteButtonText;

        &.btn-black-text {
            @include font(16px, 600, normal, 24px);

            span {
                color: $black !important;
            }
        }
    }

    &.btn-app {
        background-color: $white;
        color: $black;
        padding: 14px 24px;

        &.btn-image {
            display: flex;
            align-items: center;

            &.btn-left-img {
                justify-content: flex-start;

                img {
                    margin-right: 10px;
                }

                &.btn-center-img {
                    justify-content: center;
                }
            }

            &.btn-right-img {
                justify-content: flex-end;

                img {
                    margin-left: 10px;
                }

                &.btn-center-img {
                    justify-content: center;
                }
            }

        }
    }

    &.btn-icon {
        padding: 14px 24px;

        &.btn-image {
            display: flex;
            align-items: center;

            &.btn-left-img {
                justify-content: flex-start;

                img {
                    margin-right: 10px;
                }

                &.btn-center-img {
                    justify-content: center;
                }
            }

            &.btn-right-img {
                justify-content: flex-end;

                img {
                    margin-left: 10px;
                }

                &.btn-center-img {
                    justify-content: center;
                }
            }

        }
    }

    &.btn-link {
        padding: 0;
        box-shadow: none;
        background: transparent;
        min-width: auto;
        @include font(16, 600, normal, 24px);

        &.btn-blue {
            color: $blue;
        }

        &:active {
            transform: translateY(0px) !important;
            box-shadow: none;
        }
    }

    &:active {
        transform: translateY(3px);
        box-shadow: none;
    }

    &.disabled {
        pointer-events: none !important;
        opacity: 0.5 !important;
    }
}