@import '../../../assets/scss/variable';
@import '../../../assets/scss/mixin';

.terms-of-use {
  margin-bottom: 3rem;
  .banner-wrapper {
    height: 106px;
    background: linear-gradient(
      121.33deg,
      $gradientColor1 6.74%,
      $gradientColor2 74.57%,
      $gradientColor3 139.01%
    );
    border-radius: 0 0 30px 30px;
    margin-bottom: 55px;

    @include screen-mobile {
      height: 67px;
    }
  }
  span {
    display: inline-block;
    margin-right: 10px;
  }
  u {
    display: inline-block;
    text-decoration: none;
  }

  p {
    margin-bottom: 28px;
    @include font(16px, 400, normal, 34px);
    display: inline-block;
    text-align: justify;
  }

  i {
    font-style: italic;
  }
  strong {
    color: $darkBlue;
  }
  a {
    color: $darkBlue;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: $darkBlue;
      transition: 0.2s;
    }
  }
  h3 {
    @include font(24px, 600, normal, 36px);
    margin-top: 24px;
    margin-bottom: 24px;
    color: $darkBlue;
  }
  h4 {
    @include font(20px, 600, normal, 32px);
    margin-top: 0px;
    margin-bottom: 18px;
    color: $darkBlue;
  }
  ul {
    margin-bottom: 28px;
    li {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      text-align: justify;
      span {
        width: 40px;
        text-align: left;
        margin-right: 5px;
        @include font(16px, 400, normal, 34px);
      }
      p {
        width: 100%;
        margin-bottom: 5px;
      }
    }
  }
  .container {
    text-align: left;
  }
}
