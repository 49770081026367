@import "~bootstrap/scss/bootstrap";
@import "./assets/scss/styles.scss";

.main-content {
    .backdrop-wrapper {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: lighten($black, 10%);
        opacity: 0.5;
        visibility: hidden;
        transition: 0.2s ease-in-out;
        &.isShow {
            visibility: visible;
            transition: 0.2s ease-in-out;
        }
        &.isHide {
            visibility: hidden;
            transition: 0.2s ease-in-out;
        }
    }
}