@import '../../../assets/scss/variable';
@import '../../../assets/scss/mixin';

.footer-wrapper {
  padding-top: 46px;
  padding-bottom: 46px;
  background-color: $footerBackground;

  @include screen-tablet {
    @include gutter(9);
    padding-top: 36px;
    padding-bottom: 26px;
  }

  @include screen-mobile {
    @include gutter(9);
    padding-top: 36px;
    padding-bottom: 26px;
  }

  .footer-info-wrapper {
    @include screen-mobile {
      border-bottom: 1px solid transparent;
      margin-bottom: 26px;
      position: relative;

      &::after {
        content: '';
        width: 100%;
        height: 0.5px;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: $borderColor;
      }
    }

    .button-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 330px;

      @include screen-tablet-portrait {
        flex-direction: column;
        align-items: flex-start;
      }

      @include screen-mobile {
        flex-direction: column;
        align-items: flex-start;
      }

      .btn-app {
        padding-left: 14px;
        padding-right: 14px;
        min-width: auto;
        width: 150px !important;
        margin-bottom: 20px;

        @media screen and (max-width: 1200px) {
          width: 100% !important;
        }

        @include screen-tablet-portrait {
          margin-bottom: 14px !important;
        }

        @include screen-mobile {
          margin-bottom: 15px !important;

          &:last-child {
            margin-bottom: 0 !important;
          }
        }

        img {
          width: 22px;

          @include screen-mobile {
            width: 20px;
            margin-right: 5px;
          }
        }

        span {
          @include font(10px, 600, normal, 15px);
          color: $black;
          letter-spacing: 4%;

          @include screen-mobile {
            @include font(16px, 600, normal, 24px);
          }
        }

        &:last-child {
          @include screen-mobile {
            margin-bottom: 0px;
          }
        }

        @include screen-mobile {
          margin-bottom: 10px;
        }
      }

      a:first-child {
        margin-right: 0px;
      }
    }
  }

  .address-wrapper {
    position: relative;
    height: 100%;

    @include screen-mobile {
      height: auto;
      border-bottom: 1px solid transparent;
      margin-bottom: 26px;
      position: relative;

      &::after {
        content: '';
        width: 100%;
        height: 0.5px;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: $borderColor;
      }
    }

    ul {
      li {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 30px;

        @include screen-mobile {
          align-items: center;
          margin-bottom: 25px;

          a {
            @include font(14px, 400, normal, 20px);
          }
        }

        &:last-child {
          margin-bottom: none;
        }

        .icon {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
        }

        .text {
          @include font(14px, 400, normal, 20px);
          color: $white;
        }

        a {
          color: $white;
          text-decoration: underline;
        }
      }
    }

    &.isSeperator {
      &::before {
        content: '';
        width: 1px;
        height: 100%;
        background-color: $seperatorColor;
        position: absolute;
        left: -16px;
        top: 0;
      }

      &::after {
        content: '';
        width: 1px;
        height: 100%;
        background-color: $seperatorColor;
        position: absolute;
        right: -16px;
        top: 0;
      }
    }

    &.inDesktop {
      @include screen-mobile {
        display: none;
      }
    }
  }

  .quicklinks-wrapper {
    @include screen-mobile {
      border-bottom: 1px solid transparent;
      margin-bottom: 26px;
      position: relative;

      &::after {
        content: '';
        width: 100%;
        height: 0.5px;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: $borderColor;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      li {
        width: 50%;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 30px;

        @include screen-mobile {
          padding-left: 5px;
          padding-right: 5px;
          margin-bottom: 25px;

          a {
            @include font(14px, 400, normal, 20px);
          }
        }

        a {
          color: $white;
        }
      }
    }

    &.privacy-wrapper {
      border-bottom: none;
      margin-bottom: 0;

      @include screen-mobile {
        &::after {
          display: none;
        }
      }

      ul {
        li {
          width: auto;
          padding-left: 0;
          padding-right: 0;
          margin-right: 24px;
          margin-bottom: 18px;

          @include screen-mobile {
            width: 100%;
            margin-right: 0;
            margin-bottom: 13px;

            a {
              @include font(14px, 400, normal, 20px);
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .copyrights-wrapper {
        @include font(10px, 400, normal, 15px);
        color: $copyrightColor;
      }
    }
  }

  .social-media-icons-ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 50px;
    width: 100%;
    flex-wrap: wrap;

    @include screen-mobile {
      margin-top: 2px;
      margin-bottom: 40px;
    }

    li {
      margin-bottom: 0 !important;
      margin-right: 10px !important;
      cursor: pointer;
      width: 24px !important;
      height: 24px !important;

      &:last-child {
        margin-right: 0 !important;
      }
    }
  }
}
