.easy-account-wrapper {
    padding-top: 60px;
    padding-bottom: 100px;

    @include screen-tablet {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    @include screen-mobile {
        padding-top: 0px;
        padding-bottom: 60px;
    }

    .easy-account-steps-wrapper {
        .easy-account-steps-col {
            .easy-account-steps-item {
                display: flex;
                align-items: center;
                flex-direction: column;

                @include screen-mobile {
                    flex-direction: row;
                    height: 100%;
                    margin-bottom: 20px;
                }

                .image {
                    margin-bottom: 20px;
                    // max-height: 150px;

                    @include screen-mobile {
                        height: 100%;
                        position: relative;
                        margin-bottom: 0px;
                        margin-right: 12px;
                        max-height: initial;

                        &::after {
                            content: '';
                            width: 1px;
                            height: calc(100% - 125px);
                            position: absolute;
                            left: 50%;
                            top: 125px;
                            background-color: $black;
                        }
                    }

                    img {
                        margin: auto;
                        width: 100%;

                        @include screen-mobile {
                            width: 115px;
                            position: relative;
                            z-index: 1;
                        }
                    }
                }

                .content-body {
                    @include screen-mobile {
                        margin-bottom: 50px
                    }

                    .title {
                        @include font(24px, 600, normal, 36px);
                        color: $darkBlue;
                        margin-bottom: 10px;
                        text-align: center;
                        position: relative;
                        width: 100%;

                        @include screen-mobile {
                            @include font(20px, 600, normal, 30px);
                            text-align: left;

                            &::after,
                            &::before {
                                display: none;
                            }
                        }

                        &::after {
                            content: '';
                            width: calc(50% + 30px);
                            height: 1px;
                            background-color: $black;
                            position: absolute;
                            right: -15px;
                            top: 50%;

                            @include screen-tablet {
                                display: none;
                            }
                        }

                        &::before {
                            content: '';
                            width: 50%;
                            height: 1px;
                            background-color: $black;
                            position: absolute;
                            top: 18px;
                            left: -15px;

                            @include screen-tablet {
                                display: none;
                            }
                        }

                        @include screen-mobile {

                            &::after,
                            &::before {
                                display: none;
                            }
                        }

                        span {
                            padding-left: 10px;
                            padding-right: 10px;
                            background-color: $bodyBackground;
                            position: relative;
                            z-index: 1;

                            @include screen-mobile {
                                padding: 0;
                                background-color: transparent
                            }
                        }
                    }

                    .description {
                        @include font(18px, 400, normal, 34px);
                        color: $lightGrey;
                        text-align: center;

                        @include screen-mobile {
                            @include font(16px, 400, normal, 24px);
                            text-align: left;
                        }
                    }
                }


            }

            &:first-child {
                .easy-account-steps-item {
                    .content-body {
                        .title {
                            &::before {
                                display: none;
                            }
                        }
                    }
                }
            }

            &:last-child {
                .easy-account-steps-item {
                    .image {
                        &::after {
                            display: none;
                        }
                    }

                    .content-body {
                        margin-bottom: 0;

                        .title {
                            &::after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

    }

    &.partners-page {
        .easy-account-steps-col {
            .easy-account-steps-item {
                .content-body {
                    .title {
                        &::after {
                            display: none;
                        }

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}