@import '../../../assets/scss/variable';
@import '../../../assets/scss/mixin';

.header-wrapper {
  position: absolute;
  left: auto;
  right: auto;
  top: 0;
  z-index: 1001;
  background: transparent;
  width: 100%;
  padding-left: 36px;
  padding-right: 36px;
  display: flex;
  height: 106px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.2s;

  @include screen-mobile-tablet {
    position: fixed;
    height: 61px;
    top: 0 !important;
    @include gutter(0);
  }

  .isNotSticky {
    display: block;
  }

  .isSticky {
    display: none;
  }

  nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1440px;

    @include screen-mobile-tablet {
      position: relative;
      height: 61px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include gutter(24);

      &::after {
        content: '';
        width: 100%;
        height: 61px;
        background-color: $white;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        transform: translateY(-61px);
      }

      &.isClose {
        .isSticky {
          display: none;
        }

        .isNotSticky {
          display: block;
        }

        &::after {
          transform: translateY(-61px);
          transition: 0.2s ease-in-out;
          transition-delay: 0.2s;
        }
      }

      &.isOpen {
        .isSticky {
          display: block;
        }

        .isNotSticky {
          display: none;
        }

        &::after {
          transform: translateY(0);
          transition: 0.1s ease-in-out;
        }
      }
    }

    .logo-wrapper {
      @include screen-mobile-tablet {
        z-index: 3;
      }

      img {
        max-width: 200px;
        @include screen-mobile-tablet {
          max-width: 125px;
        }
      }
    }

    .menu-wrapper {
      @include screen-mobile-tablet {
        position: absolute;
        left: 0;
        top: 100%;
        background-color: $white;
        border-radius: 0 0 20px 20px;
        width: 100%;
        overflow: hidden;
        z-index: 1;
        transform: translateY(calc(-100% - 61px));
        transition: 0.2s ease-in-out;
      }

      .menu-wrapper-list {
        display: flex;
        justify-content: flex-start;

        @include screen-mobile-tablet {
          flex-direction: column;
        }

        .menu-wrapper-list-link {
          color: $navLinkWhite;
          text-decoration: none !important;
          @include font(20px, 600, normal, 30px);

          @include screen-laptops {
            display: flex;
            align-items: center;
          }

          @include screen-tablet {
            display: flex;
            align-items: center;
          }

          @include screen-mobile-tablet {
            width: 100%;
            color: $darkBlue;
            padding-top: 12px;
            padding-bottom: 12px;
            text-align: right;
            @include font(16px, 500, normal, 24px);
            display: flex;
            justify-content: flex-end;
            padding-right: 36px;
            padding-left: 36px;

            &.get-app-message {
              margin-top: 60px;
              margin-bottom: 22px;
            }
          }

          &.get-app-message {
            @include screen-tablet-portrait {
              @include font(20px, 500, normal, 30px);
            }
          }

          &.isActive {
            .menu-wrapper-list-item {
              &:after {
                left: 0;
                width: 100%;

                @include screen-mobile-tablet {
                  background-color: $black;
                }
              }
            }
          }

          .menu-wrapper-list-item {
            margin-right: 46px;
            position: relative;

            @include screen-tablet-landscape {
              margin-right: 26px;
              @include font(16px, 600, normal, 30px);
            }

            @include screen-tablet-portrait {
              margin-right: 15px;
              @include font(20px, 500, normal, 30px);
            }

            @include screen-mobile-tablet {
              margin-right: 0;
            }

            &.isAppIcon {
              width: 30px;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;

              @include screen-tablet-portrait {
                width: 20px;

                img {
                  width: 100%;
                }
              }

              img {
                width: 100%;
              }

              &:after {
                display: none !important;
              }
            }

            &:after {
              content: '';
              width: 0%;
              height: 2px;
              border-radius: 2px;
              position: absolute;
              bottom: -5px;
              left: 50%;
              background-color: $white;
              transition: 0.2s ease-in-out;
            }

            &:hover {
              &:after {
                left: 0;
                width: 100%;
              }
            }
          }

          &:last-child {
            margin-right: 0;
          }

          &:nth-last-child(2) {
            .menu-wrapper-list-item {
              margin-right: 0;
            }
          }

          &.isIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .menu-wrapper-list-item {
              cursor: pointer;

              &:last-child {
                margin-right: 0;
              }
            }

            @include screen-mobile-tablet {
              width: auto;
              height: auto;
              padding: 0;
              margin-left: auto;
              margin-right: 36px;
              margin-bottom: 32px;

              &::before {
                content: '';
                width: 0.5px;
                height: 100%;
                position: absolute;
                background-color: $darkBlue;
              }

              .menu-wrapper-list-item {
                &.isAppIcon {
                  position: relative;
                  margin-right: 42px;
                  margin-left: 42px;

                  img {
                    width: 100%;
                  }

                  &:first-child {
                    margin-left: 0;
                  }

                  &:last-child {
                    margin-right: 0;
                  }
                }
              }
            }
          }

          &.isSticky {
            @include screen-tablet-landscape {
              display: none;
            }

            @include screen-laptops {
              display: none;
            }
          }
        }
      }
    }

    .parent-child-wrapper {
      position: absolute;
      right: 80px;
      top: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      z-index: 3;

      @include screen-mobile {
        right: 60px;
      }

      .menu-wrapper-list-link {
        @include font(12px, 400, normal, 18px);
        color: $white;
        margin-left: 28px;
        margin-right: 28px;
        position: relative;
        text-decoration: none !important;

        @include screen-mobile-tablet {
          &::after {
            content: '';
            width: 0%;
            height: 2px;
            border-radius: 2px;
            position: absolute;
            bottom: -5px;
            left: 50%;
            background-color: $white;
            transition: 0.2s ease-in-out;
          }
        }

        &:first-child {
          margin-left: 0px !important;
        }

        &:last-child {
          margin-right: 0px !important;
        }

        @include screen-mobile-tablet {
          @include font(20px, 500, normal, 30px);
          margin-left: 14px;
          margin-right: 14px;
        }

        @include screen-mobile {
          @include font(12px, 400, normal, 18px);
          margin-left: 8px;
          margin-right: 8px;
        }

        .menu-wrapper-list-item {
          &::after {
            content: '';
            width: 0;
            height: 2px;
            border-radius: 2px;
            position: absolute;
            bottom: -5px;
            left: 50%;
            background-color: $white;
            transition: 0.2s ease-in-out;
          }
        }

        &.isActive {
          .menu-wrapper-list-item {
            &:after {
              left: 0;
              width: 100%;

              @include screen-mobile-tablet {
                background-color: $white;
              }
            }
          }
        }
      }
    }

    .hamburder-menu {
      display: none;
      cursor: pointer;

      @include screen-mobile-tablet {
        display: block;
        position: absolute;
        right: 24px;
        width: 40px;
        height: 40px;
        z-index: 3;

        .hamburder-menu-bar {
          position: absolute;
          left: 0;
          width: 100%;
          background-color: $white;
          height: 4px;
          border-radius: 4px;
          top: calc(50% - 2px);
          transition: 0.2s ease-in-out;
          transform-origin: center center;

          &:first-child {
            transform: rotate(0) translateY(-5px);
          }

          &:last-child {
            transform: rotate(0) translateY(5px);
          }
        }
      }

      @include screen-mobile {
        width: 24px;
        height: 24px;
      }
    }

    &.isOpen {
      @include screen-mobile-tablet {
        background-color: $white;

        .menu-wrapper {
          transform: translateY(0);
          transition: 0.2s ease-in-out;
        }

        .parent-child-wrapper {
          .menu-wrapper-list-link {
            color: $black;

            &.isActive {
              @include screen-mobile-tablet {
                .menu-wrapper-list-item {
                  &::after {
                    background-color: $black;
                  }
                }
              }
            }
          }
        }

        .hamburder-menu-bar {
          transform-origin: center center;
          background-color: $darkBlue;

          &:first-child {
            transform: rotate(-45deg) translateY(calc(50% - 2px));
          }

          &:last-child {
            transform: rotate(45deg) translateY(calc(50% - 2px));
          }
        }
      }
    }
  }

  &.stickey {
    background-color: $white;
    position: fixed;
    transition: 0.5s;

    nav {
      .logo-wrapper {
        .isSticky {
          width: 300px;

          @include screen-tablet-portrait {
            max-width: 150px;
          }

          @include screen-mobile-tablet {
            display: none;
            max-width: 127px;
          }
          @media (max-width: 359px) {
            max-width: 100px;
          }
        }

        .isNotSticky {
          @include screen-mobile-tablet {
            display: block;
          }
        }
      }

      .menu-wrapper {
        display: flex;
        align-items: center;

        @include screen-mobile-tablet {
          width: 100%;
          left: 0 !important;
          display: initial;
          align-items: initial;
        }

        .menu-wrapper-list {
          .menu-wrapper-list-link {
            .menu-wrapper-list-item {
              color: $navLinkBlack;

              &:after {
                background-color: $navLinkBlack;
              }

              &:hover {
                &:after {
                  background-color: $navLinkBlack;
                }
              }
            }

            &:nth-last-child(2) {
              .menu-wrapper-list-item {
                margin-right: 46px;

                @include screen-tablet-landscape {
                  margin-right: 26px;
                }

                @include screen-tablet-portrait {
                  margin-right: 15px;
                }
              }
            }
          }
        }
      }

      .parent-child-wrapper {
        z-index: 3;

        .menu-wrapper-list-link {
          margin-left: 10px !important;
          margin-right: 10px !important;
          color: $black;
          &.isActive {
            .menu-wrapper-list-item {
              &:after {
                @include screen-mobile-tablet {
                  background-color: $black;
                }
              }
            }
          }
        }
      }

      .hamburder-menu {
        .hamburder-menu-bar {
          background-color: $darkBlue;
        }
      }
    }

    .isNotSticky {
      display: none !important;
    }

    .isSticky {
      display: flex !important;
    }
  }
  .menu-open {
    display: none !important;
  }
}
