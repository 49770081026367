.content-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include screen-mobile {
    @include gutter(9);
  }

  .title-wrapper {
    @include font(32px, 400, normal, 41px);
    margin-bottom: 32px;
    color: $darkBlue;

    @include screen-tablet-portrait {
      @include font(42px, 500, normal, 60px);
    }

    @include screen-mobile {
      @include font(36px, 500, normal, 46px);
      margin-bottom: 8px;
    }
    .text {
      @include screen-mobile {
        @include font(36px, 500, normal, 46px);
      }
    }

    b {
      @include font(64px, 700, normal, 84px);

      @include screen-tablet-portrait {
        @include font(42px, 700, normal, 60px);
      }
      @include screen-mobile {
        @include font(36px, 700, normal, 46px);
      }
    }

    &.isIcon {
      display: flex;

      .icon {
        img {
          max-width: 112px;
        }
      }

      &.top {
        flex-direction: column;

        .icon {
          width: 100%;
          margin-bottom: 22px;
        }
      }

      &.right {
        justify-content: space-between;
        align-items: center;
      }

      &.left {
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
      }

      &.inline {
        justify-content: flex-start;
        align-items: flex-end;

        .text {
          margin-right: 16px;
          @include screen-mobile {
            margin-right: 0;
          }
          .right-inline {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            img {
              max-width: 120px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  .content-wrapper {
    @include font(18px, 400, normal, 34px);
    margin-bottom: 32px;

    @include screen-mobile {
      @include font(16px, 400, normal, 24px);
      margin-bottom: 24px;
    }

    &.isLink {
      a {
        color: $white;
      }
    }

    &.isList {
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          @include screen-mobile {
            margin-bottom: 24px;
            &:last-child {
              margin-bottom: 0;
            }
          }

          &.isListIcon {
            @include screen-mobile {
              margin-bottom: 24px;
            }
            .icon {
              margin-top: 5px;
              margin-right: 16px;
              width: 40px;
              height: 40px;
              img {
                width: 100%;
              }
            }
            .text {
              width: calc(100% - 60px);
            }

            &:last-child {
              margin-bottom: 0 !important;
            }
          }

          &.isListTitleDescription {
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            &:last-child {
              margin-bottom: 0;
            }
            .title {
              @include font(24px, 500, normal, 36px);
              color: $darkBlue;
            }
            .description {
              @include font(18px, 400, normal, 34px);
            }
          }
        }
      }
    }

    &.isBullets {
      ul {
        list-style-type: disc;
        li {
          padding-left: 30px;
          position: relative;
          margin-bottom: 30px;
          @include screen-mobile {
            margin-bottom: 20px;
            &::after {
              top: 10px;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
          &::after {
            content: '';
            width: 8px;
            height: 8px;
            background-color: $lightGrey;
            position: absolute;
            left: 5px;
            top: 14px;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .apps-button-wrapper {
    @include screen-mobile {
      flex-direction: column;
      margin-top: 12px;
    }

    .btn-app {
      @include screen-mobile {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
}
