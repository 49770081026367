.phone-wrapper {
    display: flex;
    justify-content: center;

    @include screen-tablet-portrait {
        height: 100%;
        align-items: center;
    }

    @include screen-mobile {
        justify-content: flex-start;
        @include gutter(9);
    }

    img {
        @include screen-tablet-portrait {
            width: 100%;
        }
        @include screen-mobile {
            width: auto;
        }
    }

    &.our-story-image {
        height: 100%;
        align-items: center;

        @include screen-mobile {
            margin-right: auto;
            margin-bottom: 24px;
        }
    }

    &.educate-your-child {
        height: 100%;
        display: flex;
        align-items: flex-end;
        @include screen-mobile {
            margin-right: 12px;
            height: auto;
            display: initial;
            align-items: initial;
        }
        .clip-video-wrapper {
            overflow: hidden;
            margin-left: 20%;
            @include screen-mobile {
                margin-left: 0;
            }
            img {
                opacity: 0;
                visibility: hidden;
                transition: 0.2s ease-in-out;
                @include screen-mobile {
                    width: 100%;
                }
            }
            @include screen-mobile {
                width: 100%;
            }

            &.isShow {
                img {
                    opacity: 1;
                    visibility: visible;
                    transition: 0.2s ease-in-out;
                }
            }
        }

        .phone-character {
            max-width: 120px;
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 2;
            img {
                width: 100%;
            }
        }
    }

    &.key-features {
        @include screen-laptops {
            position: sticky;
            top: 110px;
        }
        @include screen-tablet-landscape {
            position: sticky;
            top: 110px;
        }

    }
}