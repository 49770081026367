.card-wrapper {
    .card-wrapper-item {
        display: flex;
        justify-content: flex-start;
        border-radius: 14px;
        color: $lightGrey;
        flex-direction: column;
        align-items: center;
        background: $white;
        box-shadow: 0px 4px 0px $boxShadow1, 0px 2px 7px $boxShadow2;
        padding: 36px;
        overflow: hidden;
        position: relative;
        &::after {
            content: '';
            width: 100%;
            position: absolute;
            bottom: 0;
            background: linear-gradient(135.57deg, $blueGrad1 0.45%, $blueGrad2 50.49%);
            height: 0px;
            border-radius: 0 0 14px 14px;
            transition: 0.2s ease-in-out;
        }

        @include screen-mobile {
            padding: 26px 28px;
            margin-bottom: 12px;
            height: auto;
        }

        .card-header-container {
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;

            @include screen-mobile {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding-left: 60px;
            }

            .card-title {
                color: $darkBlue;
                @include font(24px, 500, normal, 36px);
                margin-bottom: 5px;

                @include screen-mobile {
                    @include font(20px, 600, normal, 30px);
                }
            }

            .price {
                @include font(20px, 700, normal, 30px);
                color: $darkBlue;

                @include screen-mobile {
                    @include font(16px, 600, normal, 24px);
                }
            }

        }

        .icon {
            margin-top: 20px;
            margin-bottom: 20px;
            min-height: 120px;

            @include screen-mobile {
                position: absolute;
                left: 30px;
                width: 50px;
                top: 20px;
                min-height: auto;
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }

        .sub-title {
            @include font(18px, 400, normal, 34px);
            margin-bottom: 22px;

            @include screen-mobile {
                @include font(12px, 300, normal, 18px);
                width: 100%;
                padding-left: 60px;
                text-align: left !important;
                margin-bottom: 0px;

            }
        }

        .description {
            @include font(18px, 400, normal, 34px);
            height: 0px;
            position: relative;
            overflow: hidden;
            width: 100%;
            transition: 0.2s ease-in-out;

            @include screen-mobile {
                @include font(12px, 300, normal, 18px);
                width: 100%;
                padding-left: 60px;
                text-align: left !important;
                display: none;

            }
            .description-ul {
                list-style-type: disc;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                padding-left: 20px;
            }
        }

        .button-wrapper {

            @include screen-mobile {
                margin-top: 10px;
                width: 100%;
                padding-left: 60px;
                @include font(14px, 600, normal, 18px);
                display: none;
            }

        }

        &.isActive {
            &::after {
                height: 16px;
                transition: 0.2s ease-in-out;
            }
        }

    }
}