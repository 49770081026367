@import '../../../assets/scss/variable';
@import '../../../assets/scss/mixin';

.page-not-found-wrapper {
    background: linear-gradient(121.33deg, $gradientColor1 6.74%, $gradientColor2 74.57%, $gradientColor3 139.01%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 325px);
    min-height: 500px;
    @include screen-tablet {
        height: calc(100vh - 414px);
        min-height: 500px;
    }
    @include screen-mobile {
        padding-top: 60px;
        padding-bottom: 60px;
        height: auto;
    }
    .page-not-found-container {
        width: 100%;
        color: $white;
        margin-top: 106px;
        @include screen-mobile {
            margin-top: 30px;
        }
        .heading {
            @include font(140px, 700, normal, 152px);
            @include screen-tablet {
                @include font(50px, 700, normal, 52px);
            }
            @include screen-mobile {
                @include font(42px, 600, normal, 78px);
            }
        }
        .sub-heading {
            @include font(35px, 700, normal, 48px);
            color: $white;
            margin-bottom: 10px;
            @include screen-tablet {
                @include font(24px, 600, normal, 48px);
                letter-spacing: -2%;
            }
            @include screen-mobile {
                @include font(32px, 600, normal, 48px);
                letter-spacing: -2%;
            }

        }
        .description {
            @include font(20px, 400, normal, 36px);
            letter-spacing: -2%;
            color: $white;
            margin-bottom: 24px;
            @include screen-tablet {
                @include font(14px, 400, normal, 24px);
            }
            @include screen-mobile {
                @include font(16px, 400, normal, 24px);
            }
        }
        .button-wrapper {
            .btn {
                min-width: 224px;
                @include font(16px, 600, normal, 24px);
            }
        }
        .page-not-found-text {
            height: 100%;
            display: flex;
            justify-content: center;
            span {
                @include font(250px, 500, normal, 275px);
                letter-spacing: 10%;

                &:nth-child(1) {
                    animation-delay: 0.5s;
                }

                &:nth-child(2) {
                    animation-delay: 1s;
                }

                &:nth-child(3) {
                    animation-delay: 1.5s;
                }

            }
            @include screen-tablet-portrait {
                span {
                    @include font(140px, 500, normal, 140px);
                }
                justify-content: flex-start;
                text-align: center;
                align-items: center;
            }

            @include screen-mobile {
                justify-content: flex-start;
                span {
                    @include font(130px, 500, normal, 195px);
                }
            }
        }

    }
}
